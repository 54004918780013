<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#005f32"
      spinner="bar-fade-scale" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center class="hidden-sm-and-down">
      <v-flex md10 py-4 style="border-bottom: 5px solid #828282"> </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-6 py-md-13 pa-3 pa-md-0>
      <v-flex md10 pt-sm-10 pt-md-0>
        <span class="popregular medium" style="color: black">Donations to Wildlife Trust of India are eligible for
          exemption under
          section 80 G of Income Tax Act, 1961, vide order no.
          DIT(E)/2009-20101W-110/1517 dated 02.09.2009, for Indian
          citizens.</span>
      </v-flex>
      <v-flex md10 py-10>
        <span class="popregular" style="font-size: 40px; color: black">Show your support to specific efforts of WTI
        </span>
      </v-flex>
      <v-flex md10>
        <v-layout wrap justify-center>
          <v-flex xs12 sm6 pr-sm-4 pb-12>
            <v-card flat>
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <router-link to="/DonationForm?DonationName=CONSERVATION PROJECTS">
                    <!-- <v-img
                      max-height="800px"
                      style="cursor: pointer"
                      class="M"
                      :src="mediaURL + item.frontImage"
                    ></v-img> -->
                  </router-link>
                </v-flex>
                <v-flex xs12 py-5>
                  <span class="popregular showLine2" style="color: #005f32; font-size: 24px">CONSERVATION
                    PROJECTS </span>
                </v-flex>
                <v-flex xs12>
                  <router-link to="/DonationForm?DonationName=CONSERVATION PROJECTS">
                    <v-btn x-large color="#005f32" dark>
                      <span class="popbold btn">Donate Now</span>
                    </v-btn>
                  </router-link> number</v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 sm6 pr-sm-4 pb-12>
            <v-card flat>
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <router-link to="/DonationForm?DonationName=CONSERVATION CORPUS">
                    <!-- <v-img
                    max-height="800px"
                    style="cursor: pointer"
                    class="M"
                    :src="mediaURL + item.frontImage"
                  ></v-img> -->
                  </router-link>
                </v-flex>
                <v-flex xs12 py-5>
                  <span class="popregular showLine2" style="color: #005f32; font-size: 24px">CONSERVATION CORPUS</span>
                </v-flex>
                <v-flex xs12>
                  <router-link to="/DonationForm?DonationName=CONSERVATION CORPUS">
                    <v-btn x-large color="#005f32" dark>
                      <span class="popbold btn">Donate Now</span>
                    </v-btn>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 sm6 pr-sm-4 pb-12>
            <v-card flat>
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <a href="https://rehabilitation.wti.org.in/">
                    <!-- <v-img
                  max-height="800px"
                  style="cursor: pointer"
                  class="M"
                  :src="mediaURL + item.frontImage"
                ></v-img> -->
                  </a>
                </v-flex>
                <v-flex xs12 py-5>
                  <span class="popregular showLine2" style="color: #005f32; font-size: 24px">REHABILITATION</span>
                </v-flex>
                <v-flex xs12>
                  <a href="https://rehabilitation.wti.org.in/">
                    <v-btn x-large color="#005f32" dark>
                      <span class="popbold btn">Donate Now</span>
                    </v-btn>
                  </a>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 sm6 pr-sm-4 pb-12>
            <v-card flat>
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <router-link to="/SupportCBRC?DonationName=SUPPORT CBRC">
                    <!-- <v-img
                    max-height="800px"
                    style="cursor: pointer"
                    class="M"
                    :src="mediaURL + item.frontImage"
                  ></v-img> -->
                  </router-link>
                </v-flex>
                <v-flex xs12 py-5>
                  <span class="popregular showLine2" style="color: #005f32; font-size: 24px">SUPPORT CBRC</span>
                </v-flex>
                <v-flex xs12>
                  <router-link to="/SupportCBRC?DonationName=SUPPORT CBRC">
                    <v-btn x-large color="#005f32" dark>
                      <span class="popbold btn">Donate Now</span>
                    </v-btn>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>



        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      donationList: [],
      ServerError: false,
      showSnackBar: false,
      msg: false,
      timeout: 3000,
      appLoading: false,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/donationtype/getlist",
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.donationList = response.data.data;
          } else {
            this.showSnackBar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          console.log(err());
          this.ServerError = true;
        });
    },
  },
};
</script>
<style>
.v-img :hover {
  width: 600px;
}

/* .M:hover
{
   background: gradient(to bottom, transparent 0%, black 100%) !important;
  
} */
.M:before {
  content: "";
  display: block;
  position: absolute;
  height: 0%;
  width: 100%;
  bottom: 0;
  transition: height 0.5s ease-out;
  background: linear-gradient(transparent 0%, black 100%);
}

.M:hover:before {
  height: 100%;
}

.btn:hover {
  color: #e27826;
}

.showLine2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}
</style>